.bananaContainer {  
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.banana {
  position: absolute;
  top: 0;
  left: 0;
}